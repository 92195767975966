.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: var(--rootBg);
  width: 100%;
  height: 12%;
  top: 0;
  left: 0;
  margin-top: 0;
  z-index: 1000;
  box-shadow: 0px 15px 10px -15px lightgrey;
}

.navbar .logo {
  height: 100%;
  margin: 3% 0 3% 5%;
  display: flex;
  align-items: center;
}

.navbar .logo img {
  height: 80%;
  padding: 4%;
  cursor: pointer;
  object-fit: scale-down;
}

.navbar .search-box {
  display: flex;
  height: 30px;
  line-height: 30px;
  border-radius: 30px;
  box-shadow: 1px 1px 6px 2px rgb(245, 239, 239);
  background-color: white;
}
.navbar .search-box .search-icon i {
  font-size: 16px;
  background: white;
  height: 100%;
  padding: 8px;
  position: relative;
  cursor: pointer;
  z-index: 1;
  color: lightgrey;
  border-radius: 30px;
  font-weight: 400;
}

.navbar .search-box input {
  border: none;
  outline: none;
  background: white;
  height: 100%;
  padding: 0 10px;
  font-size: 14px;
  width: 400px;
  border-radius: 30px;
}

.navbar .cart-profile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar .cart-profile .cart,
.navbar .cart-profile .profile {
  color: #9c5229;
  font-size: 35px;
}
.cart-profile {
  width: 25%;
}

#dropdown {
  position: absolute;
  width: 165px;
  background: white;
  border-radius: 5px;
  top: 50px;
  right: -5px;
  box-shadow: 1px 1px 2px 2px whitesmoke;
  padding: 15px;
}

#dropdown a {
  list-style: none;
  text-decoration: none;
  color: black;
  font-size: 15px;
}
.mobile-nav {
  display: none;
}
@media screen and (max-width: 900px) {
  .navbar {
    justify-content: space-between;
  }

  .navbar .search-box {
    width: 50%;
    height: 40%;
  }
  .navbar .cart-profile .cart,
  .navbar .cart-profile .profile {
    font-size: 25px;
  }

  .cart-profile button {
    padding: 0;
  }

  .navbar .search-box .search-icon i {
    font-size: 16px;
  }

  /* .navbar .logo img {
    width: 70px;
  } */
  .mobile-nav {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .navbar {
    display: none;
  }
  .mobile-nav {
    display: block;
  }
  /*  */
  .mobile-top-nav {
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    padding: 0.2em;
    padding-left: 1rem;
    box-shadow: 0px 15px 10px -15px lightgrey;
  }

  .mobile-logo {
    height: 80%;
  }
  .mobile-top-nav .mobile-logo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .mobile-search-box {
    position: relative;
    width: 8%;
    height: 38px;
    border-radius: 25px;
    margin-left: 50%;
    transition: all 0.5s ease-in-out;
  }
  #check:checked ~ .mobile-search-box {
    width: 100%;
    border: 2px solid lightgray;
    position: absolute;
    top: 70px;
    margin: auto;
  }

  #check {
    display: none;
  }
  .mobile-top-nav .mobile-search-box input {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    padding-left: 1.5em;
    outline: none;
    border: none;
    font-size: 1rem;
    background-color: white;
  }
  .mobile-search-icon {
    position: absolute;
    width: 40px;
    height: 100%;
    right: 0;
    font-size: 1.3rem;
    font-weight: normal;
    background-color: #fff;
    color: #9c5229;
  }

  #check:checked ~ .mobile-search-box .mobile-search-icon {
    width: 40px;
    border-radius: 0 25px 25px 0;
    color: #fff;
    background-color: #9c5229;
  }
  .mobile-search-icon i {
    position: absolute;
    right: 11px;
    top: 10px;
  }
  .mobile-bottom-nav {
    position: fixed;
    display: flex;
    justify-content: space-around;
    align-items: center;
    bottom: 0;
    overflow-x: auto;
    width: 100%;
    z-index: 1;
    height: 60px;
    background: #fff;
    padding: 2.5em 1em;
    box-shadow: 0 0 8px lightgrey;
  }
  .mobile-bottom-nav a {
    text-decoration: none;
    display: flex;
    flex-grow: 1;
    min-width: 50px;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;
    color: #444444;
  }
  .mobile-bottom-nav a:hover {
    background-color: #eeeeee;
  }
  .mobile-bottom-nav a i {
    font-size: 18px;
  }
  .mobile-bottom-nav a i:hover {
    color: #9c5229;
  }
  .mobile-bottom-nav a span:hover {
    color: #9c5229;
  }
}
/* @media screen and (max-width: 360px) {
  .mobile-logo {
    width: 35%;
  }
} */
