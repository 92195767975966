@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

:root {
  --primary: #9c5229;
  --secondary: #4afcfe;
  --rootBg: #fff8f4;
  --bodyBg: rgba(255, 248, 244, 0.3);
  /* --primary-light: #fff6f1; */
}
/*#497e63*/

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #ddd;
}

::-webkit-scrollbar-thumb {
  background: #9c5229;
  background: var(--primary);
}

html {
  scroll-behavior: smooth;
}

.category {
  width: 100%;
  background-color: white;
  padding: 20px;
}
.category ul {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  list-style: none;
  /* outline: 1px solid red; */
}
.category ul li {
  width: 20%;
  /* outline: 1px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: grey;
  cursor: pointer;
  padding: 10px;
  margin: 2px;
}
.category ul li img {
  width: clamp(50px, 10vw, 100px);
  /* outline: 1px solid yellow; */
  border-radius: 50%;
  outline: 2px solid rgba(0 0 0 / 0.1);
  transition: outline 200ms ease-in-out;
  /* background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.1)); */
}
.category ul li p {
  font-size: clamp(10px, 1.5vw, 20px);
  padding: 1vw 0;
  text-align: center;
  white-space: nowrap;
  transition: color 200ms ease-in-out;
}
.category ul li.active {
  color: var(--primary);
}
.category ul li.active img {
  outline: 2px solid var(--primary);
}
@media screen and (max-width: 600px) {
  .category ul li {
    font-size: 12px;
    padding: 7px;
  }
}

.container-slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8%;
}
.container-slider .slide {
  width: 100vw;
  height: 75vh;
}
.slider-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.arrow {
  color: grey;
  margin: 0 12px;
}

.arrow:hover {
  color: #9c5229;
}
.dots {
  /* outline: 1px solid #000000; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}
.dots .inactive {
  width: 15px;
  height: 15px;
  background-color: lightgrey;
  border-radius: 50%;
  display: inline-block;
  margin: 0 1%;
  cursor: pointer;
}
.dots .active {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #9c5229;
  display: inline-block;
  margin: 0 1%;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .container-slider {
    margin-top: 22%;
  }

  .container-slider .slide {
    width: 100vw;
    height: 50vh;
  }

  .arrow {
    margin: 0 6px;
  }
}
@media screen and (max-width: 600px) {
  .container-slider {
    margin-top: 8% 0 0 0;
    /* border: 1px solid black; */
    padding: 0;
  }
  .container-slider .slide {
    height: 30vh;
    /* border: 1px solid black; */
  }
  .dots .active,
  .dots .inactive {
    width: 10px;
    height: 10px;
  }
}

.footer-header {
  background-color: var(--rootBg);
  padding: 0.5em 1em;
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer .social-icons {
  width: 100%;
  text-align: center;
  padding: 0.5em;
  margin-bottom: 1.5em;
}
.footer .social-icons a {
  text-decoration: none;
  margin: 2em;
  color: #5a280c;
}
.footer .page-links {
  /* border: 1px solid red; */
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  margin-bottom: 1.5em;
}
.footer .page-links a {
  list-style: none;
  color: #5a280c;
  margin: 0 2em;
}
.footer-header .rights {
  text-align: center;
}
.footer-header .rights span {
  font-size: 0.8rem;
  color: #5a280c;
}
.getApp {
  display: none;
}
@media screen and (max-width: 800px) {
  .footer .page-links {
    flex-direction: column;
    padding: 0.5em 0.5em 0 0.5em;
    margin-bottom: 0em;
  }
  .footer .page-links a {
    padding-bottom: 1.5em;
  }
}
@media screen and (max-width: 500px) {
  .footer-header {
    margin-bottom: 65px;
  }
  .footer-header .rights {
    padding-bottom: 1em;
  }
  .footer .social-icons a {
    margin: 1em;
  }
  .getApp {
    display: flex;
    text-align: center;
  }
  .appStore,
  .googleStore {
    width: 65%;
    object-fit: cover;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: var(--rootBg);
  width: 100%;
  height: 12%;
  top: 0;
  left: 0;
  margin-top: 0;
  z-index: 1000;
  box-shadow: 0px 15px 10px -15px lightgrey;
}

.navbar .logo {
  height: 100%;
  margin: 3% 0 3% 5%;
  display: flex;
  align-items: center;
}

.navbar .logo img {
  height: 80%;
  padding: 4%;
  cursor: pointer;
  object-fit: scale-down;
}

.navbar .search-box {
  display: flex;
  height: 30px;
  line-height: 30px;
  border-radius: 30px;
  box-shadow: 1px 1px 6px 2px rgb(245, 239, 239);
  background-color: white;
}
.navbar .search-box .search-icon i {
  font-size: 16px;
  background: white;
  height: 100%;
  padding: 8px;
  position: relative;
  cursor: pointer;
  z-index: 1;
  color: lightgrey;
  border-radius: 30px;
  font-weight: 400;
}

.navbar .search-box input {
  border: none;
  outline: none;
  background: white;
  height: 100%;
  padding: 0 10px;
  font-size: 14px;
  width: 400px;
  border-radius: 30px;
}

.navbar .cart-profile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar .cart-profile .cart,
.navbar .cart-profile .profile {
  color: #9c5229;
  font-size: 35px;
}
.cart-profile {
  width: 25%;
}

#dropdown {
  position: absolute;
  width: 165px;
  background: white;
  border-radius: 5px;
  top: 50px;
  right: -5px;
  box-shadow: 1px 1px 2px 2px whitesmoke;
  padding: 15px;
}

#dropdown a {
  list-style: none;
  text-decoration: none;
  color: black;
  font-size: 15px;
}
.mobile-nav {
  display: none;
}
@media screen and (max-width: 900px) {
  .navbar {
    justify-content: space-between;
  }

  .navbar .search-box {
    width: 50%;
    height: 40%;
  }
  .navbar .cart-profile .cart,
  .navbar .cart-profile .profile {
    font-size: 25px;
  }

  .cart-profile button {
    padding: 0;
  }

  .navbar .search-box .search-icon i {
    font-size: 16px;
  }

  /* .navbar .logo img {
    width: 70px;
  } */
  .mobile-nav {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .navbar {
    display: none;
  }
  .mobile-nav {
    display: block;
  }
  /*  */
  .mobile-top-nav {
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    padding: 0.2em;
    padding-left: 1rem;
    box-shadow: 0px 15px 10px -15px lightgrey;
  }

  .mobile-logo {
    height: 80%;
  }
  .mobile-top-nav .mobile-logo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .mobile-search-box {
    position: relative;
    width: 8%;
    height: 38px;
    border-radius: 25px;
    margin-left: 50%;
    transition: all 0.5s ease-in-out;
  }
  #check:checked ~ .mobile-search-box {
    width: 100%;
    border: 2px solid lightgray;
    position: absolute;
    top: 70px;
    margin: auto;
  }

  #check {
    display: none;
  }
  .mobile-top-nav .mobile-search-box input {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    padding-left: 1.5em;
    outline: none;
    border: none;
    font-size: 1rem;
    background-color: white;
  }
  .mobile-search-icon {
    position: absolute;
    width: 40px;
    height: 100%;
    right: 0;
    font-size: 1.3rem;
    font-weight: normal;
    background-color: #fff;
    color: #9c5229;
  }

  #check:checked ~ .mobile-search-box .mobile-search-icon {
    width: 40px;
    border-radius: 0 25px 25px 0;
    color: #fff;
    background-color: #9c5229;
  }
  .mobile-search-icon i {
    position: absolute;
    right: 11px;
    top: 10px;
  }
  .mobile-bottom-nav {
    position: fixed;
    display: flex;
    justify-content: space-around;
    align-items: center;
    bottom: 0;
    overflow-x: auto;
    width: 100%;
    z-index: 1;
    height: 60px;
    background: #fff;
    padding: 2.5em 1em;
    box-shadow: 0 0 8px lightgrey;
  }
  .mobile-bottom-nav a {
    text-decoration: none;
    display: flex;
    flex-grow: 1;
    min-width: 50px;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;
    color: #444444;
  }
  .mobile-bottom-nav a:hover {
    background-color: #eeeeee;
  }
  .mobile-bottom-nav a i {
    font-size: 18px;
  }
  .mobile-bottom-nav a i:hover {
    color: #9c5229;
  }
  .mobile-bottom-nav a span:hover {
    color: #9c5229;
  }
}
/* @media screen and (max-width: 360px) {
  .mobile-logo {
    width: 35%;
  }
} */

.products-outer-box {
  background-color: var(--bodyBg);
}
.products {
  /* border: 2px solid black; */
  width: 100%;
  padding: 0.5em;
  padding-bottom: 2%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(230px, 230px));
  /* background-color: var(--rootBg); */
  /* grid-template-columns: 250px 250px 250px 250px; */
  grid-gap: 1rem;
}
.product-card {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(223 194 177 / 0.5);
  display: flex;
  flex-direction: column;
  position: relative;
}
.img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
  padding: 0.7em 0;
}
.product-card-content {
  --prod-title-color: rgb(66, 66, 66);

  background-color: var(--rootBg);
  padding: 0.7em;
}
.title {
  text-align: center;
  margin: 0 auto 0.5rem auto;
  font-size: 0.8em;
  color: var(--prod-title-color);
  font-weight: lighter;
}

.product-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: -0.8em;
  margin-bottom: 0;
}

.product-content p {
  font-size: 0.8em;
  padding: 5% 0;
  font-weight: lighter;
  color: var(--prod-title-color);
}

.info-button {
  position: absolute;
  top: 5%;
  right: 5%;
}

.watchlist-button {
  position: absolute;
  top: 5%;
  left: 5%;
}

.alertButton .alertText {
  display: none;
  width: 450%;
  color: #333;
  padding: 20% 10%;
  text-align: center;
  border-radius: 6px;
  text-transform: capitalize;
  background-color: hsla(0, 50%, 100%, 0.9);
  line-height: 1.75em;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 80%;
  left: 50%;
  -webkit-transform: translate(-95%, 0);
          transform: translate(-95%, 0);
}

.alertButton:hover .alertText,
.alertButton:active .alertText {
  display: inline-block;
}

/* .alertButton:hover,
.alertButton:active {
  background-color: #9c5229;
} */

.wa-hover {
  position: fixed;
  z-index: 100;
  bottom: 7vh;
  right: 2vw;
}

.wa-hover a {
  background-color: #25d366;
  color: white;
}

.scroll-top {
  background-color: #ffdfcc;
  cursor: pointer;
  position: fixed;
  bottom: 5%;
  left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7em;
  border-radius: 30px;
  transition: visibility 300ms ease-in-out;
  z-index: 1000;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  visibility: hidden;
}

.scroll-top.showBtn {
  visibility: visible;
}

.outofstock {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding-bottom: 50%;
  /* border: 1px dotted red; */
  font-size: 1.5rem;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  white-space: nowrap;
  z-index: 10;
  /* background-color: rgb(65, 187, 202); */
}

.outofstock div {
  position: absolute;
  right: 0;
  left: 0;
  background-color: rgb(65, 187, 202, 0.6);

  color: #fff;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

@media screen and (max-width: 500px) {
  .scroll-top {
    bottom: 17%;
  }
}

@media screen and (max-width: 560px) {
  .products {
  }

  .products {
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
  }
  .title {
    font-size: 0.9rem;
  }
  .alertButton .alertText {
    width: 400%;
    font-size: 0.8rem;
    -webkit-transform: translate(-85%, 0);
            transform: translate(-85%, 0);
  }
  .info-button {
    top: 2%;
  }

  .watchlist-button {
    top: 2%;
  }

  .wa-hover {
    bottom: 15vh;
    right: 5vw;
  }
  .outofstock {
    font-size: 1rem;
    padding-bottom: 100%;
  }
}

.cart-outer-box {
  background-color: var(--bodyBg);
  /* height: 100vh; */
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

.alertLogin {
  position: relative;
  display: inline-block;
}

.alertLogin .alertText {
  display: none;
  width: 220px;
  max-height: 60px;
  background-color: #ddd;
  color: #333;
  text-align: center;
  border-radius: 6px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 110%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.alertLogin:hover .alertText,
.alertLogin:active .alertText {
  display: inline-block;
}

/* input[type='date']:in-range::-webkit-datetime-edit-year-field,
input[type='date']:in-range::-webkit-datetime-edit-month-field,
input[type='date']:in-range::-webkit-datetime-edit-day-field,
input[type='date']:in-range::-webkit-datetime-edit-text {
  display: none;
} */

.checkout {
  display: grid;
  width: 90%;
  margin: 7% auto 0;
  padding: 20px;
}
.checkout .checkout-address-block {
  padding: 10px;
  display: grid;

  align-items: center;
  box-shadow: 0px 3px 1px -3px lightgrey;
}
.checkout .checkout-address-block .heading {
  font-size: 20px;
  border-bottom: 2px solid lightgrey;
  padding: 10px;
  margin: auto;
}
.checkout .checkout-address-block .checkout-block {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding: 20px 0;
  grid-gap: 10px;
}
.checkout-address {
  margin: 0 20px;
  padding: 15px;
  box-shadow: 1px 1px 2px 2px lightgray;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.checkout-address .checkout-details .name {
  font-size: 15px;
  font-weight: 600;
}
.checkout-address input[type='radio'] {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout-address label {
  text-align: center;
}
#radio-btn:checked ~ label {
  display: block;
}

.blur {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
}
.address-form {
  width: 90vw;
  display: flex;
  background: white;
  flex-direction: column;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  z-index: 1000;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.address-form h2 {
  text-align: center;
  color: var(--primary);
  font-size: clamp(1rem, 3vw, 2rem);
  margin: 1.5% 0;
}

.or-line {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.or-line span {
  background-color: white;
  color: gray;
  padding: 0.5%;
  border-radius: 50%;
}

.or-line::before {
  content: '';
  position: absolute;
  border-top: 2px solid lightgray;
  top: 50%;
  right: 1%;
  left: 1%;
  z-index: -1;
}

@media screen and (min-width: 600px) {
  .address-form {
    width: 80vw;
  }
}

.gm-style-iw > button {
  display: none !important;
}

.gmnoprint {
  display: none !important;
}

.gm-style-cc > div > a {
  display: none !important;
}

/* 
#GMapsID div div a div img {
  display: none !important;
} */

@media screen and (max-width: 600px) {
  .gm-style-iw span {
    font-size: 3vw !important;
  }
}


.about {
  display: grid;
  margin-top: 10px;
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  height: auto;
  background-image: url(/static/media/bg3.0f7b528f.jpg);

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: whitesmoke;
  background-attachment: fixed;
  position: relative;
  opacity: 0.6;
}
.contact-details {
  /* width: 70%; */
  padding: 0.7em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* background-image: url('./bg3.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: whitesmoke;
  opacity: 0.7; */
}
.email,
.phone {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.aboutUs {
  max-width: 900px;
  padding: 20px;
}
.aboutUs .content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.aboutUs .content h3 {
  color: #9c5229;
  font-weight: 500;
  margin: 3px;
  font-size: 25px;
  cursor: pointer;
}
.aboutUs .content p {
  text-align: center;
  font-size: 16px;
  word-spacing: 1px;
  color: black;
  margin: 15px;
  letter-spacing: 1px;
}
.FC {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.farmers,
.customers {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 10px 30px;
  border-radius: 20px;
  background: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}
.farmers ul li,
.customers ul li {
  margin: 13px;
  font-size: 18px;
  word-spacing: 1px;
  list-style: none;
  letter-spacing: 1px;
}
.farmers ul li::before,
.customers ul li::before {
  content: '\2022';
  color: #5e3219;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 16px;
}
.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  margin: 20px auto 0 auto;
}
@media screen and (max-width: 630px) {
  .FC {
    flex-direction: column;
  }
  .farmers,
  .customers {
    margin-bottom: 10px;
  }
  .aboutUs .content p {
    font-size: 14px;
  }
  .farmers ul li,
  .customers ul li {
    font-size: 14px;
  }
  .aboutUs .content h3 {
    font-size: 17px;
    font-weight: bold;
  }
  .contact-details {
    flex-direction: column;
  }
  .about {
    background-size: 100% 100%;
  }
}

.policy {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  margin: 10px 30px;
  /* background-image: url('./bg1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
}
.policy h3 {
  margin: 20px 0;
}
.policy-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.policy-div h5 {
  font-size: 17px;
  color: #9c5229;
}
.policy-div p {
  text-align: justify;
  font-size: 13px;
  color: black;
}

.policy {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  margin: 10px 30px;
}
.policy h3 {
  margin: 20px 0;
}
.policy-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.policy-div h5 {
  font-size: 17px;
  color: #9c5229;
}
.policy-div p {
  text-align: justify;
  font-size: 13px;
  color: black;
}

.policy {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  margin: 10px 30px;
}
.policy h3 {
  margin: 20px 0;
}
.policy-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.policy-div h5 {
  font-size: 17px;
  color: #9c5229;
}
.policy-div p {
  text-align: justify;
  font-size: 13px;
  color: black;
}

.cs-root {
  width: 100vw;
  height: 100vh;
  background-image: url(/static/media/coming-soon-rer-mandi.a20eef7c.jpg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.logo-pop {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
}

.form {
  min-width: 25vw;
  min-height: 30vh;
  position: relative;
  margin: auto 0;
  padding: 20px;
  /* display: grid; */
  background: white;
  grid-gap: 10px;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  z-index: 1000;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media screen and (max-width: 560px) {
  .form {
    width: 90vw;
  }
}

.form .remove {
  position: absolute;
  top: 10px;
  right: 10px;
}

.create-form {
  max-height: 250px;
  overflow-y: auto;
}

.create-form > div {
  margin: 2% 1%;
  width: 98%;
}

.groupedText {
  display: flex;
  margin: 2% 1%;
}

.groupedText > div {
  flex-grow: 1;
  margin: 0 1%;
}

.groupedCol {
  display: 'flex';
  margin: 2% 1%;
}

.groupedCol > div {
  flex-grow: 1;
  margin: 0 1%;
}

.pop-title {
  text-align: center;
  font-size: larger;
  color: #9c5229;
}

.fields {
  width: 100%;
  /* margin: 10%; */
}

.error {
  font-size: smaller;
  color: red;
}

.check-terms input {
  margin-right: 10px;
}

.loading-body {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadImgBox {
  width: 10em;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* outline: 1px solid red; */
  overflow-x: scroll;
  white-space: nowrap;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.loadImgBox::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.loadImgBox img {
  /* border: 1px solid green; */
  width: 33%;
  padding: 5%;
  -webkit-animation: load 400ms ease-in-out infinite;
          animation: load 400ms ease-in-out infinite;
}

@-webkit-keyframes load {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes load {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

