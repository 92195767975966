.about {
  display: grid;
  margin-top: 10px;
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  height: auto;
  background-image: url('./bg3.jpg');

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: whitesmoke;
  background-attachment: fixed;
  position: relative;
  opacity: 0.6;
}
.contact-details {
  /* width: 70%; */
  padding: 0.7em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* background-image: url('./bg3.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: whitesmoke;
  opacity: 0.7; */
}
.email,
.phone {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.aboutUs {
  max-width: 900px;
  padding: 20px;
}
.aboutUs .content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.aboutUs .content h3 {
  color: #9c5229;
  font-weight: 500;
  margin: 3px;
  font-size: 25px;
  cursor: pointer;
}
.aboutUs .content p {
  text-align: center;
  font-size: 16px;
  word-spacing: 1px;
  color: black;
  margin: 15px;
  letter-spacing: 1px;
}
.FC {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.farmers,
.customers {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 10px 30px;
  border-radius: 20px;
  background: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}
.farmers ul li,
.customers ul li {
  margin: 13px;
  font-size: 18px;
  word-spacing: 1px;
  list-style: none;
  letter-spacing: 1px;
}
.farmers ul li::before,
.customers ul li::before {
  content: '\2022';
  color: #5e3219;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 16px;
}
.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  margin: 20px auto 0 auto;
}
@media screen and (max-width: 630px) {
  .FC {
    flex-direction: column;
  }
  .farmers,
  .customers {
    margin-bottom: 10px;
  }
  .aboutUs .content p {
    font-size: 14px;
  }
  .farmers ul li,
  .customers ul li {
    font-size: 14px;
  }
  .aboutUs .content h3 {
    font-size: 17px;
    font-weight: bold;
  }
  .contact-details {
    flex-direction: column;
  }
  .about {
    background-size: 100% 100%;
  }
}
