.blur {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
}
.address-form {
  width: 90vw;
  display: flex;
  background: white;
  flex-direction: column;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  z-index: 1000;
  position: fixed;
  transform: translate(-50%, -50%);
}

.address-form h2 {
  text-align: center;
  color: var(--primary);
  font-size: clamp(1rem, 3vw, 2rem);
  margin: 1.5% 0;
}

.or-line {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.or-line span {
  background-color: white;
  color: gray;
  padding: 0.5%;
  border-radius: 50%;
}

.or-line::before {
  content: '';
  position: absolute;
  border-top: 2px solid lightgray;
  top: 50%;
  right: 1%;
  left: 1%;
  z-index: -1;
}

@media screen and (min-width: 600px) {
  .address-form {
    width: 80vw;
  }
}
