.gm-style-iw > button {
  display: none !important;
}

.gmnoprint {
  display: none !important;
}

.gm-style-cc > div > a {
  display: none !important;
}

/* 
#GMapsID div div a div img {
  display: none !important;
} */

@media screen and (max-width: 600px) {
  .gm-style-iw span {
    font-size: 3vw !important;
  }
}
