.loading-body {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadImgBox {
  width: 10em;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* outline: 1px solid red; */
  overflow-x: scroll;
  white-space: nowrap;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.loadImgBox::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.loadImgBox img {
  /* border: 1px solid green; */
  width: 33%;
  padding: 5%;
  animation: load 400ms ease-in-out infinite;
}

@keyframes load {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
