.cart-outer-box {
  background-color: var(--bodyBg);
  /* height: 100vh; */
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

.alertLogin {
  position: relative;
  display: inline-block;
}

.alertLogin .alertText {
  display: none;
  width: 220px;
  max-height: 60px;
  background-color: #ddd;
  color: #333;
  text-align: center;
  border-radius: 6px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 110%;
  left: 50%;
  transform: translate(-50%, 0);
}

.alertLogin:hover .alertText,
.alertLogin:active .alertText {
  display: inline-block;
}

/* input[type='date']:in-range::-webkit-datetime-edit-year-field,
input[type='date']:in-range::-webkit-datetime-edit-month-field,
input[type='date']:in-range::-webkit-datetime-edit-day-field,
input[type='date']:in-range::-webkit-datetime-edit-text {
  display: none;
} */
