.category {
  width: 100%;
  background-color: white;
  padding: 20px;
}
.category ul {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  list-style: none;
  /* outline: 1px solid red; */
}
.category ul li {
  width: 20%;
  /* outline: 1px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: grey;
  cursor: pointer;
  padding: 10px;
  margin: 2px;
}
.category ul li img {
  width: clamp(50px, 10vw, 100px);
  /* outline: 1px solid yellow; */
  border-radius: 50%;
  outline: 2px solid rgba(0 0 0 / 0.1);
  transition: outline 200ms ease-in-out;
  /* background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.1)); */
}
.category ul li p {
  font-size: clamp(10px, 1.5vw, 20px);
  padding: 1vw 0;
  text-align: center;
  white-space: nowrap;
  transition: color 200ms ease-in-out;
}
.category ul li.active {
  color: var(--primary);
}
.category ul li.active img {
  outline: 2px solid var(--primary);
}
@media screen and (max-width: 600px) {
  .category ul li {
    font-size: 12px;
    padding: 7px;
  }
}
