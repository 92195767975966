.footer-header {
  background-color: var(--rootBg);
  padding: 0.5em 1em;
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer .social-icons {
  width: 100%;
  text-align: center;
  padding: 0.5em;
  margin-bottom: 1.5em;
}
.footer .social-icons a {
  text-decoration: none;
  margin: 2em;
  color: #5a280c;
}
.footer .page-links {
  /* border: 1px solid red; */
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  margin-bottom: 1.5em;
}
.footer .page-links a {
  list-style: none;
  color: #5a280c;
  margin: 0 2em;
}
.footer-header .rights {
  text-align: center;
}
.footer-header .rights span {
  font-size: 0.8rem;
  color: #5a280c;
}
.getApp {
  display: none;
}
@media screen and (max-width: 800px) {
  .footer .page-links {
    flex-direction: column;
    padding: 0.5em 0.5em 0 0.5em;
    margin-bottom: 0em;
  }
  .footer .page-links a {
    padding-bottom: 1.5em;
  }
}
@media screen and (max-width: 500px) {
  .footer-header {
    margin-bottom: 65px;
  }
  .footer-header .rights {
    padding-bottom: 1em;
  }
  .footer .social-icons a {
    margin: 1em;
  }
  .getApp {
    display: flex;
    text-align: center;
  }
  .appStore,
  .googleStore {
    width: 65%;
    object-fit: cover;
  }
}
