.checkout {
  display: grid;
  width: 90%;
  margin: 7% auto 0;
  padding: 20px;
}
.checkout .checkout-address-block {
  padding: 10px;
  display: grid;

  align-items: center;
  box-shadow: 0px 3px 1px -3px lightgrey;
}
.checkout .checkout-address-block .heading {
  font-size: 20px;
  border-bottom: 2px solid lightgrey;
  padding: 10px;
  margin: auto;
}
.checkout .checkout-address-block .checkout-block {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding: 20px 0;
  grid-gap: 10px;
}
.checkout-address {
  margin: 0 20px;
  padding: 15px;
  box-shadow: 1px 1px 2px 2px lightgray;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.checkout-address .checkout-details .name {
  font-size: 15px;
  font-weight: 600;
}
.checkout-address input[type='radio'] {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout-address label {
  text-align: center;
}
#radio-btn:checked ~ label {
  display: block;
}
