@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

:root {
  --primary: #9c5229;
  --secondary: #4afcfe;
  --rootBg: #fff8f4;
  --bodyBg: rgba(255, 248, 244, 0.3);
  /* --primary-light: #fff6f1; */
}
/*#497e63*/

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #ddd;
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
}

html {
  scroll-behavior: smooth;
}
