.policy {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  margin: 10px 30px;
}
.policy h3 {
  margin: 20px 0;
}
.policy-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.policy-div h5 {
  font-size: 17px;
  color: #9c5229;
}
.policy-div p {
  text-align: justify;
  font-size: 13px;
  color: black;
}
