.products-outer-box {
  background-color: var(--bodyBg);
}
.products {
  /* border: 2px solid black; */
  width: 100%;
  padding: 0.5em;
  padding-bottom: 2%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(230px, 230px));
  /* background-color: var(--rootBg); */
  /* grid-template-columns: 250px 250px 250px 250px; */
  grid-gap: 1rem;
}
.product-card {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(223 194 177 / 0.5);
  display: flex;
  flex-direction: column;
  position: relative;
}
.img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
  padding: 0.7em 0;
}
.product-card-content {
  --prod-title-color: rgb(66, 66, 66);

  background-color: var(--rootBg);
  padding: 0.7em;
}
.title {
  text-align: center;
  margin: 0 auto 0.5rem auto;
  font-size: 0.8em;
  color: var(--prod-title-color);
  font-weight: lighter;
}

.product-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: -0.8em;
  margin-bottom: 0;
}

.product-content p {
  font-size: 0.8em;
  padding: 5% 0;
  font-weight: lighter;
  color: var(--prod-title-color);
}

.info-button {
  position: absolute;
  top: 5%;
  right: 5%;
}

.watchlist-button {
  position: absolute;
  top: 5%;
  left: 5%;
}

.alertButton .alertText {
  display: none;
  width: 450%;
  color: #333;
  padding: 20% 10%;
  text-align: center;
  border-radius: 6px;
  text-transform: capitalize;
  background-color: hsla(0, 50%, 100%, 0.9);
  line-height: 1.75em;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 80%;
  left: 50%;
  transform: translate(-95%, 0);
}

.alertButton:hover .alertText,
.alertButton:active .alertText {
  display: inline-block;
}

/* .alertButton:hover,
.alertButton:active {
  background-color: #9c5229;
} */

.wa-hover {
  position: fixed;
  z-index: 100;
  bottom: 7vh;
  right: 2vw;
}

.wa-hover a {
  background-color: #25d366;
  color: white;
}

.scroll-top {
  background-color: #ffdfcc;
  cursor: pointer;
  position: fixed;
  bottom: 5%;
  left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7em;
  border-radius: 30px;
  transition: visibility 300ms ease-in-out;
  z-index: 1000;
  user-select: none;
  visibility: hidden;
}

.scroll-top.showBtn {
  visibility: visible;
}

.outofstock {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding-bottom: 50%;
  /* border: 1px dotted red; */
  font-size: 1.5rem;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  white-space: nowrap;
  z-index: 10;
  /* background-color: rgb(65, 187, 202); */
}

.outofstock div {
  position: absolute;
  right: 0;
  left: 0;
  background-color: rgb(65, 187, 202, 0.6);

  color: #fff;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

@media screen and (max-width: 500px) {
  .scroll-top {
    bottom: 17%;
  }
}

@media screen and (max-width: 560px) {
  .products {
  }

  .products {
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
  }
  .title {
    font-size: 0.9rem;
  }
  .alertButton .alertText {
    width: 400%;
    font-size: 0.8rem;
    transform: translate(-85%, 0);
  }
  .info-button {
    top: 2%;
  }

  .watchlist-button {
    top: 2%;
  }

  .wa-hover {
    bottom: 15vh;
    right: 5vw;
  }
  .outofstock {
    font-size: 1rem;
    padding-bottom: 100%;
  }
}
