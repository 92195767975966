.container-slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8%;
}
.container-slider .slide {
  width: 100vw;
  height: 75vh;
}
.slider-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.arrow {
  color: grey;
  margin: 0 12px;
}

.arrow:hover {
  color: #9c5229;
}
.dots {
  /* outline: 1px solid #000000; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}
.dots .inactive {
  width: 15px;
  height: 15px;
  background-color: lightgrey;
  border-radius: 50%;
  display: inline-block;
  margin: 0 1%;
  cursor: pointer;
}
.dots .active {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #9c5229;
  display: inline-block;
  margin: 0 1%;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .container-slider {
    margin-top: 22%;
  }

  .container-slider .slide {
    width: 100vw;
    height: 50vh;
  }

  .arrow {
    margin: 0 6px;
  }
}
@media screen and (max-width: 600px) {
  .container-slider {
    margin-top: 8% 0 0 0;
    /* border: 1px solid black; */
    padding: 0;
  }
  .container-slider .slide {
    height: 30vh;
    /* border: 1px solid black; */
  }
  .dots .active,
  .dots .inactive {
    width: 10px;
    height: 10px;
  }
}
